import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import { DiscordIcon, GithubIcon, TelegramIcon, XIcon, YoutubeIcon } from "../assets";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="px-4 md:px-6 py-6 border-t border-wow bg-black footer">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
          <div className="flex justify-center md:justify-normal">
            <Link to="/" className="block">
              <img className="w-16" src={logo} alt="Media" />
            </Link>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4 items-center justify-center">
              <div>
                <Link to="" className="block">
                  <XIcon />
                </Link>
              </div>

              <div>
                <Link to="" className="block">
                  <TelegramIcon />
                </Link>
              </div>

              <div>
                <Link to="" className="block">
                  <DiscordIcon />
                </Link>
              </div>

              <div>
                <Link to="" className="block">
                  <YoutubeIcon />
                </Link>
              </div>

              <div>
                <Link to="" className="block">
                  <GithubIcon />
                </Link>
              </div>
            </div>

            <div className="flex gap-6 items-center justify-center">
              <Link href='' className="text-white text-sm md:text-base block">Terms of Sale</Link>
              <Link href='' className="text-white text-sm md:text-base block">Privacy Policy</Link>
              <Link href='' className="text-white text-sm md:text-base block">Terms of Service</Link>
            </div>
          </div>

          <div className="text-base text-white text-center md:text-end">@2024 WOW PRESALE</div>
        </div>

      </div>
    </React.Fragment>
  );
}
