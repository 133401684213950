"use client";
import React, { useState } from "react";


export default function Progressbar() {
  const [currentRaisedInBnb] = useState(0);
  const [bnbCap] = useState(0);

  return (
    <React.Fragment>
      <div className="progress w-full mt-5">
        <div
          className="progress-bar text-sm md:text-base font-semibold"
          role="progressbar"
          style={{
            width:
              (
                (currentRaisedInBnb / bnbCap) *
                100
              ).toString() + "%" || "0%",
          }}
          aria-valuenow={88}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div className="progress-percentage">
            BNB Raised: &nbsp;{" "}
            <span className="text-[#6bbc67]">
              {currentRaisedInBnb || "-"} / {bnbCap || "-"}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
