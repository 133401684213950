"use client";
import React from 'react';
import { CloseIcon } from '../assets';
import logo from "../images/logo.svg";
import walletConnect from "../images/walletConnect.jpg";
import metamask from "../images/metamask.png";

export default function WalletModal({ setShowWalletModal }) {
  return (
    <React.Fragment>
      {/* Connect Wallet Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[110] outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            <div className="mt-10 absolute md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-3xl rounded-tr-3xl md:rounded-3xl shadow-modal flex flex-col w-[450px] bg-[#00000054] outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowWalletModal(false)}>
                <CloseIcon />
              </button>
              <div className='modal-content rounded-tl-2xl rounded-tr-2xl md:rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-11'>
                  <h2 className='text-[32px] text-center font-bold text-white'>
                    Connect Wallet
                  </h2>
                  <p className='text-[18px] text-center text-white'>Select your favorite wallet to log in.</p>
                </div>
                <div className='mb-6'>
                  <button className='flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6'>
                    <div className='flex items-center gap-4 font-semibold text-white'>
                      <img className="w-10" src={logo} alt="Media" />
                      <span>WOW Wallet</span>
                    </div>
                    <div className='text-xs rounded py-1 px-4 text-black bg-primary-gradient font-medium'>Popular</div>
                  </button>

                  <button className='flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6'>
                    <div className='flex items-center gap-4 font-semibold text-white'>
                      <img className="w-10" src={metamask} alt="Media" />
                      <span>Metamask</span>
                    </div>
                  </button>

                  <button className='flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6'>
                    <div className='flex items-center gap-4 font-semibold text-white'>
                      <img className="w-10 rounded" src={walletConnect} alt="Media" />
                      <span>Wallet Connect</span>
                    </div>
                  </button>

                </div>
                {/*body*/}

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* Connect Wallet Modal Ends Here */}
    </React.Fragment>
  )
}
