export const CopyIcon = ({ handleCopy }) => {
  return (
    <svg
      onClick={handleCopy}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 1024 1024"
      className="min-w-4 max-w-4 hover:cursor-pointer"
    >
      <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
    </svg>
  );
};

export const WarnIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 1024 1024"
      className="min-w-4 max-w-4"
    >
      <path d="M464 720a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" />
    </svg>
  );
};

export const XIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
        fill="#fff"
      />
    </svg>
  );
};

export const TelegramIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3196 20.1495C17.5882 20.3391 17.9332 20.3868 18.2428 20.2709C18.5496 20.1536 18.7773 19.8891 18.8455 19.5686C19.5696 16.1663 21.3273 7.55209 21.986 4.45796C22.0351 4.22341 21.9533 3.98068 21.7705 3.82522C21.5851 3.6684 21.3314 3.62477 21.1051 3.70795C17.6114 5.00206 6.84942 9.03984 2.45027 10.6667C2.17345 10.7703 1.99208 11.0376 2.00027 11.3335C2.00981 11.6281 2.20891 11.8831 2.49391 11.969C4.46712 12.5594 7.05533 13.3804 7.05533 13.3804C7.05533 13.3804 8.26626 17.0349 8.89627 18.8922C8.97536 19.1268 9.15809 19.3095 9.39809 19.3723C9.63946 19.435 9.89583 19.3695 10.0758 19.1991C11.089 18.2445 12.6531 16.7649 12.6531 16.7649C12.6531 16.7649 15.6314 18.9468 17.3196 20.1495ZM8.14489 12.9167L9.544 17.5313L9.85628 14.609L18.3409 6.95754C18.4309 6.87572 18.4432 6.73935 18.3682 6.6439C18.2946 6.54844 18.1568 6.52526 18.0546 6.58935L8.14626 12.9194L8.14489 12.9167Z"
        fill="#fff"
      />
    </svg>
  );
};

export const DiscordIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2727 6.54548C19.2727 6.54548 17.1882 4.91366 14.7273 4.72729L14.5055 5.17184C16.73 5.71639 17.7509 6.49548 18.8182 7.45457C16.9791 6.51548 15.1636 5.63639 12 5.63639C8.83636 5.63639 7.02182 6.51548 5.18182 7.45457C6.24909 6.49548 7.46364 5.62911 9.49455 5.17184L9.27273 4.72729C6.69091 4.97093 4.72727 6.54548 4.72727 6.54548C4.72727 6.54548 2.4 9.92093 2 16.5455C4.34545 19.2518 7.90909 19.2727 7.90909 19.2727L8.65455 18.28C7.38909 17.84 5.96182 17.0546 4.72727 15.6364C6.19909 16.75 8.42091 17.9091 12 17.9091C15.58 17.9091 17.8009 16.75 19.2727 15.6364C18.0382 17.0546 16.6118 17.84 15.3455 18.28L16.0909 19.2727C16.0909 19.2727 19.6545 19.2518 22 16.5455C21.6 9.92093 19.2727 6.54548 19.2727 6.54548ZM9.04545 14.7273C8.16636 14.7273 7.45455 13.9137 7.45455 12.9091C7.45455 11.9046 8.16636 11.0909 9.04545 11.0909C9.92455 11.0909 10.6364 11.9046 10.6364 12.9091C10.6364 13.9137 9.92455 14.7273 9.04545 14.7273ZM14.9545 14.7273C14.0755 14.7273 13.3636 13.9137 13.3636 12.9091C13.3636 11.9046 14.0755 11.0909 14.9545 11.0909C15.8336 11.0909 16.5455 11.9046 16.5455 12.9091C16.5455 13.9137 15.8336 14.7273 14.9545 14.7273Z"
        fill="#fff"
      />
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0575 6.94499C21.938 6.50102 21.704 6.09623 21.3789 5.77112C21.0538 5.44601 20.649 5.212 20.205 5.09249C18.57 4.64999 12 4.64999 12 4.64999C12 4.64999 5.43 4.64999 3.795 5.09249C3.35103 5.212 2.94623 5.44601 2.62113 5.77112C2.29602 6.09623 2.062 6.50102 1.9425 6.94499C1.63721 8.61244 1.48906 10.3049 1.5 12C1.48906 13.6951 1.63721 15.3875 1.9425 17.055C2.062 17.499 2.29602 17.9038 2.62113 18.2289C2.94623 18.554 3.35103 18.788 3.795 18.9075C5.43 19.35 12 19.35 12 19.35C12 19.35 18.57 19.35 20.205 18.9075C20.649 18.788 21.0538 18.554 21.3789 18.2289C21.704 17.9038 21.938 17.499 22.0575 17.055C22.3628 15.3875 22.5109 13.6951 22.5 12C22.5109 10.3049 22.3628 8.61244 22.0575 6.94499ZM9.9 15.15V8.84999L15.3525 12L9.9 15.15Z"
        fill="#fff"
      />
    </svg>
  );
};

export const GithubIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 98 96"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
        fill="#fff"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.34 9.322C16.4361 9.23244 16.5136 9.12484 16.568 9.00533C16.6225 8.88583 16.6529 8.75676 16.6575 8.62551C16.6621 8.49425 16.6408 8.36337 16.5948 8.24035C16.5488 8.11733 16.4791 8.00457 16.3895 7.9085C16.2999 7.81244 16.1923 7.73496 16.0728 7.68049C15.9533 7.62601 15.8243 7.5956 15.693 7.59101C15.5618 7.58641 15.4309 7.60771 15.3079 7.6537C15.1848 7.69968 15.0721 7.76944 14.976 7.859L12.05 10.587L9.32201 7.66C9.13948 7.47305 8.89102 7.36496 8.6298 7.35887C8.36859 7.35278 8.11536 7.44917 7.92431 7.62741C7.73327 7.80565 7.61957 8.05159 7.60755 8.3126C7.59553 8.57361 7.68615 8.82896 7.86001 9.024L10.588 11.95L7.66101 14.678C7.56155 14.7667 7.48082 14.8743 7.42354 14.9946C7.36626 15.1149 7.3336 15.2454 7.32747 15.3785C7.32135 15.5116 7.34188 15.6446 7.38786 15.7696C7.43384 15.8946 7.50434 16.0092 7.59523 16.1067C7.68612 16.2041 7.79555 16.2824 7.91711 16.3369C8.03867 16.3914 8.16991 16.4211 8.3031 16.4242C8.4363 16.4273 8.56877 16.4038 8.69274 16.355C8.81671 16.3062 8.92968 16.2331 9.02501 16.14L11.951 13.413L14.679 16.339C14.7671 16.4403 14.8747 16.5228 14.9953 16.5816C15.116 16.6405 15.2472 16.6744 15.3813 16.6814C15.5153 16.6885 15.6494 16.6684 15.7756 16.6225C15.9017 16.5766 16.0173 16.5058 16.1155 16.4142C16.2137 16.3227 16.2924 16.2123 16.3471 16.0897C16.4017 15.9671 16.4311 15.8347 16.4334 15.7005C16.4358 15.5663 16.4112 15.433 16.3609 15.3085C16.3107 15.1841 16.2359 15.071 16.141 14.976L13.414 12.05L16.34 9.322Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z"
        fill="white"
      />
    </svg>
  );
};

export const TickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="currentColor"
    x="0px"
    y="0px"
    className="min-w-4 max-w-4"
    viewBox="0 0 48 48"
  >
    <path
      fill="#43A047"
      d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"
    ></path>
  </svg>
);
