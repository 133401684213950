export const BASE_SEPOLIA_CONFIG = Object.freeze({
  chainId: "0x14A34",
  chainName: "Base Sepolia Testnet",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://sepolia.base.org"],
  blockExplorerUrls: ["https://sepolia.basescan.org"],
});

export const BSC_TESTNET_CONFIG = Object.freeze({
  chainId: "0x61",
  chainName: "BNB Chain Testnet",
  nativeCurrency: {
    name: "TBNB",
    symbol: "tBNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-testnet.public.blastapi.io"],
  blockExplorerUrls: ["https://testnet.bscscan.com/"],
});
