import erc20Abi from "../web3/erc20.json";
import presaleContractAbi from "../web3/presale.json";
import vestingContractAbi from "../web3/vesting.json";

export const ERC20_CONTRACT_ADDRESS =
  "0xA594ebb9fF8c7c5cc33FeeEa2bc1a6762cf233e0";
export const PRESALE_CONTRACT_ADDRESS =
  "0x4dcb12397C07e497edfc34cd5ebDdB5226391822";
export const VESTING_CONTRACT_ADDRESS =
  "0x78B90Db8b09E23e6f0109C5B051bab4cF41EEf64";

export const CONTRACT_EXPLORER_URL = `https://testnet.bscscan.com/address/${ERC20_CONTRACT_ADDRESS}`;

export const ERC20_CONTRACT_ABI = erc20Abi;
export const PRESALE_CONTRACT_ABI = presaleContractAbi;
export const VESTING_CONTRACT_ABI = vestingContractAbi;

export const PRESALE_START_DATE = 1730419200000; // Friday, November 1, 2024 12:00:00 AM
export const PRESALE_END_DATE = 1735689599000; // Tuesday, 31 December 2024 23:59:59 UTC

export const VESTING_START_DATE = 1735689600000; // Wednesday, January 1, 2025 12:00:00 AM
export const VESTING_END_DATE = 1751241600000; // Monday, June 30, 2025 12:00:00 AM
