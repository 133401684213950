import { useCallback, useEffect, useState } from "react";

export const CountDown = ({ endDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = endDate - Date.now();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  }, [endDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft, endDate]);

  return (
    <div className="grid grid-cols-4 gap-3 md:gap-5 mt-5 mb-8 digi-font">
      <div className="bg-primary-gradient clip-path p-1 rounded-md md:shadow-pump">
        <div className="bg-timer-gradient text-base clip-path md:text-2xl tracking-[2px] py-1 rounded-[5px] font-bold text-white">
          {timeLeft.days}
          <p className="text-center text-[11px] text-white uppercase font-normal">
            Day
          </p>
        </div>
      </div>
      <div className="bg-primary-gradient clip-path p-1 rounded-md md:shadow-pump">
        <div className="bg-timer-gradient text-base clip-path md:text-2xl tracking-[2px] py-1 rounded-[5px] font-bold text-white">
          {timeLeft.hours}
          <p className="text-center text-[11px] text-white uppercase font-normal">
            hrs
          </p>
        </div>
      </div>
      <div className="bg-primary-gradient clip-path p-1 rounded-md md:shadow-pump">
        <div className="bg-timer-gradient text-base clip-path md:text-2xl tracking-[2px] py-1 rounded-[5px] font-bold text-white">
          {timeLeft.minutes}
          <p className="text-center text-[11px] text-white uppercase font-normal">
            mins
          </p>
        </div>
      </div>
      <div className="bg-primary-gradient shadow-pump clip-path p-1 rounded-md md:shadow-pump">
        <div className="bg-timer-gradient text-base clip-path md:text-2xl tracking-[2px] py-1 rounded-[5px] font-bold text-white">
          {timeLeft.seconds}
          <p className="text-center text-[11px] text-white uppercase font-normal">
            secs
          </p>
        </div>
      </div>
    </div>
  );
};
