import React, { useState } from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as CopyIcon } from "./../images/copy.svg";
import { ReactComponent as LogoutIcon } from "./../images/logout.svg";
import WalletModal from "./WalletModal";

function MiddleTruncate({ text, maxLength }) {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const startLength = Math.floor((maxLength - 3) / 2); // Beginning part
  const endLength = Math.ceil((maxLength - 3) / 2); // End part

  const truncatedText = `${text.slice(0, startLength)}...${text.slice(
    -endLength
  )}`;

  return <span>{truncatedText}</span>;
}

export default function Header({
  walletAddress,
  connectWallet,
  disconnectWallet = () => { },
}) {
  const [copied, setCopied] = useState(false);
  const [showWalletModal, setShowWalletModal] = React.useState(false);

  const handleCopy = () => {
    if (walletAddress) {
      navigator.clipboard
        .writeText(walletAddress)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((error) =>
          console.error("Failed to copy wallet address:", error)
        );
    }
  };

  return (
    <div>
      {showWalletModal && (<WalletModal setShowWalletModal={setShowWalletModal} />)}
      <div className="bg-[#09110e] fixed top-0 z-[100] w-full flex justify-between text-white px-5 md:px-10 py-3 md:py-4">
        <div className="flex w-full justify-between items-center">
          <Link to="/" className="block">
            <img className="w-16" src={logo} alt="Media" />
          </Link>
          {walletAddress ? (
            <div className="flex items-center gap-4">
              <div className="hover:cursor-pointer">
                <CopyIcon width={15} onClick={handleCopy} aria-label="Copy" />
              </div>
              <div className="flex flex-col">
                <MiddleTruncate text={walletAddress} maxLength={12} />
                {copied && (
                  <span className="text-sm text-green-500">Copied</span>
                )}
              </div>
              <button onClick={disconnectWallet}>
                <LogoutIcon fill="red" width={18} height={18} />
              </button>
            </div>
          ) : (
            <button
              onClick={connectWallet}
              // onClick={() => setShowWalletModal(true)}
              className="rounded-xl px-5 hover:shadow-pump py-2 min-h-11 bg-hover-gradient hover:bg-primary-gradient text-base font-bold"
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
