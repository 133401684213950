"use client";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Timer() {
  return (
    <React.Fragment>
      <Header />
      <div className="w-[90%] md:w-3/4 pt-28 mx-auto timer-page">
        <h1 className="font-bold text-2xl md:text-7xl text-center text-white">Supersale is LIVE</h1>
        <p className="text-[18px] text-gray-500 text-center mt-5">Sale ends in</p>

        <div className="flex justify-center items-center gap-4 md:gap-6 mb-6 mt-4">
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl md:text-[60px] font-bold text-white leading-none">28</p>
            <p className="text-sm text-[#a0aec0]">DAYS</p>
          </div>
          <p className="font-bold text-white text-2xl md:text-[60px] mb-4">:</p>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl md:text-[60px] font-bold text-white leading-none">14</p>
            <p className="text-sm text-[#a0aec0]">HOURS</p>
          </div>
          <p className="font-bold text-white text-2xl md:text-[60px] mb-4">:</p>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl md:text-[60px] font-bold text-white leading-none">01</p>
            <p className="text-sm text-[#a0aec0]">MINUTES</p>
          </div>
          <p className="font-bold text-white text-2xl md:text-[60px] mb-4">:</p>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl md:text-[60px] font-bold text-white leading-none">01</p>
            <p className="text-sm text-[#a0aec0]">SECONDS</p>
          </div>
        </div>

        <div className="flex justify-center items-center gap-3">
          <button className="bg-wow-green border-wow-green border hover:bg-transparent hover:text-wow-green text-sm font-semibold text-black px-4 py-2 uppercase">Sale Details</button>
          <button className="bg-transparent border-wow-green border text-sm font-semibold hover:bg-wow-green hover:text-black text-wow-green px-4 py-2 uppercase">About Presale</button>
        </div>

        <div className="faq my-11">
          <h1 className="text-white font-bold text-center text-2xl mb-4 md:text-[42px]">Frequently Asked Questions</h1>
          <p className="text-sm md:text-base text-center text-gray-400 mb-8">Quick answers to common questions you may have</p>

          <div className="faq-drawer">
            <input className="faq-drawer__trigger" id="faq-drawer" type="checkbox" />
            <label className="faq-drawer__title" htmlFor="faq-drawer">
              What tokens can I use?
            </label>
            <div className="faq-drawer__content-wrapper">
              <div className="faq-drawer__content">
                <p>
                  The Supersale is accepting both USDC and USDT stablecoins.
                </p>
              </div>
            </div>
          </div>

          <div className="faq-drawer">
            <input className="faq-drawer__trigger" id="faq-drawer2" type="checkbox" />
            <label className="faq-drawer__title" htmlFor="faq-drawer2">
              Who can participate in the token sale?
            </label>
            <div className="faq-drawer__content-wrapper">
              <div className="faq-drawer__content">
                <p>
                  Participation in the token sale is open to individuals and entities from most regions, provided they meet the necessary legal and regulatory requirements. However, due to regulatory restrictions, individuals from certain territories are not eligible to participate. To verify your eligibility, please refer to our Terms of Service.
                  <br /><br />
                  Please make sure to review your local laws and regulations regarding cryptocurrency investments before participating. By joining the token sale, participants confirm that they are not residents or citizens of any restricted territories.
                </p>
              </div>
            </div>
          </div>

          <div className="faq-drawer">
            <input className="faq-drawer__trigger" id="faq-drawer3" type="checkbox" />
            <label className="faq-drawer__title" htmlFor="faq-drawer3">
              What percentage of the tokens are being sold and at what valuation?
            </label>
            <div className="faq-drawer__content-wrapper">
              <div className="faq-drawer__content">
                <p>
                  20% of the total token supply is being sold in this token sale. The token sale is based on a fully diluted valuation (FDV) of $100 million. This means the valuation represents the total value of the project when all tokens (including those locked or yet to be distributed) are in circulation.
                </p>
              </div>
            </div>
          </div>

          <div className="faq-drawer">
            <input className="faq-drawer__trigger" id="faq-drawer4" type="checkbox" />
            <label className="faq-drawer__title" htmlFor="faq-drawer4">
              What is the token distribution for $SUPR like?
            </label>
            <div className="faq-drawer__content-wrapper">
              <div className="faq-drawer__content">
                <p>
                  For a detailed breakdown of the Superseed token distribution, including allocation across different areas, please refer to our tokenomics article. This will provide a comprehensive overview of how the tokens are allocated and distributed.
                </p>
              </div>
            </div>
          </div>

          <div className="faq-drawer">
            <input className="faq-drawer__trigger" id="faq-drawer5" type="checkbox" />
            <label className="faq-drawer__title" htmlFor="faq-drawer5">
              What is the token sale price?
            </label>
            <div className="faq-drawer__content-wrapper">
              <div className="faq-drawer__content">
                <p>
                  The token sale features a dynamic pricing structure that ranges from $0.0091 to $0.01026 per token, depending on how early participants join. This pricing is part of our early bird incentivization model, designed to reward early participants with better pricing.
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </React.Fragment>
  );
}
