"use client";
import { toast } from "react-toastify";
import logo from "./../images/logo.svg";
import bnb from "./../images/bnb.webp";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useContract } from "../hooks/useContract";
import { useWallet } from "../hooks/useWallet";
import ClaimToken from "../components/ClaimToken";
import { CopyIcon, WarnIcon, TickIcon } from "../assets";
import { Input } from "../components/Input";
import { CountDown } from "../components/CountDown";
import { formatUnix } from "../utils/formatUnixTime";
import { Tooltip } from "react-tooltip";

import {
  CONTRACT_EXPLORER_URL,
  ERC20_CONTRACT_ADDRESS,
  PRESALE_END_DATE,
  PRESALE_START_DATE,
  VESTING_END_DATE,
  VESTING_START_DATE,
} from "../utils/constants";
import { Link } from "react-router-dom";

export default function LandingPage() {
  const {
    connectWallet,
    ethBalance,
    walletAddress,
    signer,
    disconnectWallet,
    refreshBalance,
  } = useWallet();
  const {
    fetchTokenBalance,
    fetchTokenPrice,
    buyTokens,
    fetchVestingInfo,
    fetchUnlockedTokens,
    hasVestingStarted,
    claimTokens,
    getTotalRaisedBnb,
    getBnbCap,
    getMaxPurchaseAmount,
    getMinPurchaseAmount,
  } = useContract(signer);

  const [ethValue, setEthValue] = useState(null);
  const [wowValue, setWowValue] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const [vestingStarted, setVestingStarted] = useState(false);
  const [tokenPrice, setTokenPrice] = useState();
  const [maxPurchaseLimitInBnb, setMaxPurchaseLimitInBnb] = useState(0);
  const [minPurchaseLimitInBnb, setMinPurchaseLimitInBnb] = useState(0);
  const [bnbCap, setBnbCap] = useState(0);
  const [currentRaisedInBnb, setCurrentRaisedInBnb] = useState(0);
  const [currentRaisedInWOW, setCurrentRaisedInWOW] = useState(0);
  const [wowCap, setWowCap] = useState(0);

  const [unlockedTokens, setUnlockedTokens] = useState(0.0);
  const [copied, setCopied] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [vestingInfo, setVestingInfo] = useState({
    totalTokens: 0.0,
    claimedTokens: 0.0,
  });

  useEffect(() => {
    const checkVestingStarted = async () => {
      const hasStarted = await hasVestingStarted();
      setVestingStarted(!!hasStarted);
    };
    checkVestingStarted();
  }, [hasVestingStarted]);

  useEffect(() => {
    const fetchTokenData = async () => {
      setTokenPrice(await fetchTokenPrice());
      setBnbCap(await getBnbCap());

      const totalRaisedBnb = await getTotalRaisedBnb();
      setCurrentRaisedInBnb(totalRaisedBnb);
      setCurrentRaisedInWOW(calculatePriceByBnb(totalRaisedBnb).toFixed(3));
      setWowCap(calculatePriceByBnb(bnbCap));
      setMaxPurchaseLimitInBnb(await getMaxPurchaseAmount());
      setMinPurchaseLimitInBnb(await getMinPurchaseAmount());
    };
    fetchTokenData();

    const calculatePriceByBnb = (_bnbValue) => {
      return _bnbValue / tokenPrice;
    };
  }, [
    bnbCap,
    currentRaisedInBnb,
    fetchTokenBalance,
    fetchTokenPrice,
    getBnbCap,
    getMaxPurchaseAmount,
    getMinPurchaseAmount,
    getTotalRaisedBnb,
    tokenPrice,
    walletAddress,
  ]);

  useEffect(() => {
    const fetchVestingData = async () => {
      if (vestingStarted && walletAddress) {
        const info = await fetchVestingInfo(walletAddress);
        setVestingInfo({
          totalTokens: info.totalTokens,
          claimedTokens: info.claimedTokens,
        });
        setUnlockedTokens(await fetchUnlockedTokens(walletAddress));
      }
    };
    fetchVestingData();
  }, [vestingStarted, fetchVestingInfo, fetchUnlockedTokens, walletAddress]);

  const onInputChangeHandler = (e) => {
    validateInput(e.target.value);
  };

  const validateInput = (ethValue) => {
    setErrorMessage("");
    setEthValue(ethValue);
    if (tokenPrice) {
      setWowValue((ethValue / tokenPrice).toFixed(3));
      if (isNaN(ethValue)) {
        setErrorMessage("Invalid input");
        return false;
      }
      if (walletAddress) {
        if (!ethValue) {
          setErrorMessage("Please enter the BNB amount");
          return false;
        }
        if (ethBalance < ethValue) {
          setErrorMessage("Insufficient balance");
          return false;
        }
        if (ethValue < minPurchaseLimitInBnb) {
          setErrorMessage("Value is below minimum purchase limit");
          return false;
        }

        if (ethValue > maxPurchaseLimitInBnb) {
          setErrorMessage("Value is above maximum purchase limit");
          return false;
        }
      }
    }
    return true;
  };

  const maxClickHandler = () => {
    if (walletAddress) {
      setErrorMessage("");
      setEthValue(ethBalance);
      setWowValue((ethBalance / tokenPrice).toFixed(3));
    }
  };

  const onBuyClick = () => {
    if (validateInput(ethValue)) {
      setIsLoading(true);
      buyTokens(ethValue).then(async (res) => {
        if (res.status) {
          toast.success("Tokens purchased successfully");
          setCurrentRaisedInBnb(currentRaisedInBnb + ethValue);
          refreshBalance(walletAddress);
        } else {
          toast.error(res.message.substring(0, 50) + "...");
        }
        setIsLoading(false);
      });
    }
  };

  const onClaimClick = async () => {
    await claimTokens().then((res) => {
      if (res.status) {
        const unlocked = Number(unlockedTokens);
        setVestingInfo({
          totalTokens: Number(vestingInfo.totalTokens),
          claimedTokens: Number(vestingInfo.claimedTokens) + unlocked,
        });
        setUnlockedTokens(0);
        toast.success("Tokens claimed successfully");
      } else {
        toast.error(res.message.substring(0, 50) + "...");
      }
    });
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(ERC20_CONTRACT_ADDRESS);
    setCopied("Copied");
    setTimeout(() => {
      setCopied("");
    }, 1000);
  };

  return (
    <React.Fragment>
      <div className="text-center">
        <Header
          walletAddress={walletAddress}
          connectWallet={connectWallet}
          disconnectWallet={disconnectWallet}
        />
        <div className="main-container">
          <div className="flex lg:flex-nowrap flex-wrap w-full gap-7">
            <div className="profile-section px-5 bg-[#132d30] rounded-xl w-full">
              <div className="h-52 bg-flash wow-cover relative -mx-5 rounded-t-xl">
                <div className="absolute w-32 h-32 rounded-full left-1/2  -translate-x-1/2 -bottom-14">
                  <img
                    src={logo}
                    className="w-full rounded-full shadow-pump"
                    alt="Media"
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <div className="my-7 bg-[#089387] w-max rounded-full flex items-center text-white gap-1 px-3 py-1 text-xs font-medium">
                  <div className="w-2 h-2 rounded-full bg-[#0dff00]"></div>
                  Sale live
                </div>
              </div>

              <div className="flex justify-center">
                <h1 className="text-[26px] gradient font-semibold">
                  WOW Presale
                </h1>
              </div>

              <div className="bg-primary-gradient h-[2px] w-full rounded-full my-3"></div>

              <div className="intro-wrapper mb-8">
                <h1 className="text-white text-left text-xl font-bold mt-5">
                  About
                </h1>
                <p className="text-white text-sm my-5 text-justify">
                  Welcome to the WOW Presale! The WOW token is your gateway to
                  the future of decentralized innovation, offering unique
                  opportunities within the blockchain ecosystem. Join our
                  growing community and secure your stake in this revolutionary
                  journey today!
                </p>

                <div className="token-details">
                  <h1 className="text-white text-left text-xl font-bold mt-5">
                    Token
                  </h1>

                  <div className="details py-[10px]">
                    <div className="flex justify-between items-start gap-6 md:gap-2">
                      <p className="text-[18px] text-white">Address</p>
                      <div>
                        <Tooltip id="copied-tooltip" isOpen={true} />
                        <Tooltip id="contract-address-tooltip" />

                        <span className="flex items-start text-end break-all gap-1 text-sm text-green-300">
                          <Link
                            data-tooltip-id="contract-address-tooltip"
                            data-tooltip-content={"View on Explorer"}
                            data-tooltip-variant="dark"
                            to={CONTRACT_EXPLORER_URL}
                            rel="noreferrer"
                            target={"_blank"}
                          >
                            {ERC20_CONTRACT_ADDRESS}
                          </Link>
                          <div
                            data-tooltip-id="copied-tooltip"
                            data-tooltip-content={copied}
                            data-tooltip-variant="dark"
                          >
                            {copied ? (
                              <TickIcon />
                            ) : (
                              <CopyIcon handleCopy={handleCopyClick} />
                            )}
                          </div>
                        </span>

                        <p className="flex text-justify items-start justify-end gap-2 text-xs text-yellow-600">
                          <WarnIcon />
                          Do not send BNB to the token address
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-primary-gradient rounded-2xl p-2 my-5">
                    {vestingStarted ? (
                      <ClaimToken
                        walletAddress={walletAddress}
                        lockedTokens={vestingInfo.totalTokens}
                        claimedTokens={vestingInfo.claimedTokens}
                        claimableTokens={unlockedTokens}
                        onClaimHandler={onClaimClick}
                        onConnectHandler={connectWallet}
                      />
                    ) : (
                      <div className="bg-black py-5 md:py-7 px-5 md:px-9 rounded-xl">
                        <p className="text-center digi-font text-xl font-bold text-white">
                          Presale Ends In
                        </p>

                        <CountDown
                          endDate={vestingStarted ? 0 : PRESALE_END_DATE}
                        />

                        <div className="text-xl flex justify-center gap-1 font-bold text-center text-white">
                          1 BNB = <img src={logo} className="w-5" alt="Media" />{" "}
                          {tokenPrice ? <>{1 / tokenPrice}</> : "-"}
                        </div>

                        <div className="progress w-full mt-5">
                          <div
                            className="progress-bar text-sm md:text-base font-semibold"
                            role="progressbar"
                            style={{
                              width:
                                (
                                  (currentRaisedInBnb / bnbCap) *
                                  100
                                ).toString() + "%" || "0%",
                            }}
                            aria-valuenow={88}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <div className="progress-percentage">
                              BNB Raised: &nbsp;{" "}
                              <span className="text-[#6bbc67]">
                                {currentRaisedInBnb || "-"} / {bnbCap || "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between text-sm md:text-base font-bold text-white">
                          <div>Tokens Sold</div>
                          <div>
                            {currentRaisedInWOW || 0} / {wowCap || "-"}
                          </div>
                        </div>

                        <div className="mt-5 w-full">
                          <div className="group fields w-full">
                            <div className="relative w-full flex flex-col">
                              <Input
                                label={"Amount in BNB you pay"}
                                name={"ethValue"}
                                placeholder={"0.0"}
                                value={ethValue}
                                onChange={onInputChangeHandler}
                                hasButton={true}
                                buttonClickHandler={maxClickHandler}
                                errorMessage={errorMessage}
                              />
                            </div>

                            <div className="flex justify-between items-center pt-2">
                              <p className="text-sm font-bold text-white">
                                BNB
                              </p>
                              <p className="text-base font-bold text-white flex gap-1 items-center">
                                <img src={bnb} className="w-5" alt="Media" />
                                {ethBalance}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-5">
                          <div className="flex w-full items-start justify-between">
                            <p className="text-left text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              Amount in <span className="text-wow">WOW</span>{" "}
                              You Receive:
                            </p>
                            <p className="flex justify-end gap-1 text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              <img src={logo} className="w-5" alt="Media" />{" "}
                              {wowValue}
                            </p>
                          </div>
                        </div>

                        <div className="details mt-5 py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] text-white capitalize">
                              Max buy
                            </p>
                            <p className="text-[18px] flex items-center gap-1 text-white">
                              <img src={bnb} className="w-6 h-6" alt="Media" />{" "}
                              {maxPurchaseLimitInBnb || 0}
                            </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] text-white capitalize">
                              Min buy
                            </p>
                            <p className="text-[18px] flex items-center gap-1 text-white">
                              <img src={bnb} className="w-6 h-6" alt="Media" />{" "}
                              {minPurchaseLimitInBnb || 0}
                            </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] text-left text-white capitalize">
                              Current raised
                            </p>
                            <p className="text-[18px] whitespace-nowrap flex text-end items-center gap-1 text-white">
                              <img src={bnb} className="w-6 h-6" alt="Media" />{" "}
                              {currentRaisedInBnb ? (
                                <>
                                  {currentRaisedInBnb || "-"} (
                                  {(
                                    (currentRaisedInBnb / bnbCap) *
                                    100
                                  ).toFixed(2)}
                                  %)
                                </>
                              ) : (
                                " -"
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="">
                          {walletAddress ? (
                            <button
                              disabled={errorMessage.length > 0 || isLoading}
                              onClick={() => onBuyClick()}
                              className={`p-3 ${
                                errorMessage || isLoading
                                  ? "bg-gray-500"
                                  : "bg-hover-gradient"
                              }  ${
                                !errorMessage &&
                                !isLoading &&
                                "hover:shadow-pump"
                              } min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white`}
                            >
                              Buy with BNB
                            </button>
                          ) : (
                            <button
                              onClick={connectWallet}
                              className="p-3 bg-hover-gradient hover:shadow-pump min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white"
                            >
                              CONNECT WALLET
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="pool-details mt-8">
                  <h1 className="text-white text-left text-xl font-bold mb-2">
                    Pool Info
                  </h1>

                  <div className="details py-[10px] border-b border-dashed border-flash">
                    <div className="flex justify-between items-center">
                      <p className="text-[18px] text-white capitalize">
                        Total Cap
                      </p>
                      <p className="text-[18px] flex items-center gap-1 text-white">
                        <img src={bnb} className="w-6 h-6" alt="Media" />{" "}
                        {bnbCap || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="details py-[10px] border-b border-dashed border-flash">
                    <div className="flex justify-between items-center">
                      <p className="text-[18px] text-white capitalize">
                        Presale rate
                      </p>
                      <div>
                        <p className="text-[18px] flex items-center gap-1 text-white">
                          <img src={bnb} className="w-6 h-6" alt="Media" /> 1 =
                          <img
                            src={logo}
                            className="w-6 h-6"
                            alt="Media"
                          />{" "}
                          {tokenPrice ? <>{1 / tokenPrice}</> : "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="details py-[10px] border-b border-dashed border-flash">
                    <div className="flex justify-between items-center">
                      <p className="text-[18px] text-white capitalize">
                        Start Time
                      </p>
                      <p className="text-[18px] text-white">
                        {formatUnix(PRESALE_START_DATE)}
                      </p>
                    </div>
                  </div>

                  <div className="details py-[10px] border-b border-dashed border-flash">
                    <div className="flex justify-between items-center">
                      <p className="text-[18px] text-white capitalize">
                        End time
                      </p>
                      <p className="text-[18px] text-white">
                        {formatUnix(PRESALE_END_DATE)}
                      </p>
                    </div>
                  </div>
                </div>

                <details>
                  <summary>Vesting Info</summary>
                  <div>
                    <div className="details py-[10px] border-b border-dashed border-flash">
                      <div className="flex justify-between items-center">
                        <p className="text-[18px] text-white capitalize">
                          Unlock at TGE:
                        </p>
                        <p className="text-[18px] flex gap-1 text-white">30%</p>
                      </div>
                    </div>

                    <div className="details py-[10px] border-b border-dashed border-flash">
                      <div className="flex justify-between items-center">
                        <p className="text-[18px] text-white capitalize">
                          Linear Unlock (6 months):
                        </p>
                        <p className="text-[18px] flex gap-1 text-white">70%</p>
                      </div>
                    </div>

                    <div className="details py-[10px] border-b border-dashed border-flash">
                      <div className="flex justify-between items-center">
                        <p className="text-[18px] text-white capitalize">
                          Start Time
                        </p>
                        <p className="text-[18px] flex gap-1 text-white">
                          {formatUnix(VESTING_START_DATE)}
                        </p>
                      </div>
                    </div>

                    <div className="details py-[10px] border-b border-dashed border-flash">
                      <div className="flex justify-between items-center">
                        <p className="text-[18px] text-white capitalize">
                          End time
                        </p>
                        <p className="text-[18px] flex gap-1 text-white">
                          {formatUnix(VESTING_END_DATE)}
                        </p>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
