import { useState, useEffect, useCallback } from "react";
import { Contract, ethers } from "ethers";
import {
  ERC20_CONTRACT_ADDRESS,
  ERC20_CONTRACT_ABI,
  PRESALE_CONTRACT_ADDRESS,
  PRESALE_CONTRACT_ABI,
  VESTING_CONTRACT_ADDRESS,
  VESTING_CONTRACT_ABI,
} from "../utils/constants";
import { BSC_TESTNET_CONFIG } from "../utils/config";

export const useContract = (signer) => {
  const [erc20Contract, setErc20Contract] = useState(null);
  const [presaleContract, setPresaleContract] = useState(null);
  const [vestingContract, setVestingContract] = useState(null);

  useEffect(() => {
    const setupContracts = async () => {
      const publicRpcProvider = new ethers.JsonRpcProvider(
        BSC_TESTNET_CONFIG.rpcUrls[0]
      );

      setErc20Contract(
        new Contract(
          ERC20_CONTRACT_ADDRESS,
          ERC20_CONTRACT_ABI,
          publicRpcProvider
        )
      );
      setPresaleContract(
        new Contract(
          PRESALE_CONTRACT_ADDRESS,
          PRESALE_CONTRACT_ABI,
          publicRpcProvider
        )
      );
      setVestingContract(
        new Contract(
          VESTING_CONTRACT_ADDRESS,
          VESTING_CONTRACT_ABI,
          publicRpcProvider
        )
      );

      if (signer) {
        setErc20Contract(
          new Contract(ERC20_CONTRACT_ADDRESS, ERC20_CONTRACT_ABI, signer)
        );
        setPresaleContract(
          new Contract(PRESALE_CONTRACT_ADDRESS, PRESALE_CONTRACT_ABI, signer)
        );
        setVestingContract(
          new Contract(VESTING_CONTRACT_ADDRESS, VESTING_CONTRACT_ABI, signer)
        );
      }
    };
    setupContracts();
  }, [signer]);

  const fetchTokenBalance = async (walletAddress) => {
    if (erc20Contract) {
      try {
        return ethers.formatEther(await erc20Contract.balanceOf(walletAddress));
      } catch (error) {
        console.error("Error fetching eth balance:", error);
      }
    }
  };

  const fetchTokenPrice = async () => {
    if (presaleContract) {
      try {
        return ethers.formatEther(await presaleContract.tokenPrice());
      } catch (error) {
        console.log("Error fetching token price");
      }
    }
  };

  const fetchVestingInfo = useCallback(
    async (walletAddress) => {
      if (vestingContract && walletAddress) {
        try {
          const [totalTokens, claimedTokens] =
            await vestingContract.beneficiaries(walletAddress);
          return {
            totalTokens: ethers.formatEther(totalTokens),
            claimedTokens: ethers.formatEther(claimedTokens),
          };
        } catch (error) {
          console.error("Error fetching vesting info:", error);
          return { totalTokens: 0, claimedTokens: 0 };
        }
      }
    },
    [vestingContract]
  );

  const fetchUnlockedTokens = useCallback(
    async (walletAddress) => {
      if (vestingContract && walletAddress) {
        try {
          const unlockedTokens = await vestingContract.getUnlockedTokens(
            walletAddress
          );
          return ethers.formatEther(unlockedTokens);
        } catch (error) {
          console.error("Error fetching unlocked tokens:", error);
          return 0;
        }
      }
    },
    [vestingContract]
  );

  const hasVestingStarted = useCallback(async () => {
    if (vestingContract) {
      try {
        return await vestingContract.vestingStart();
      } catch (error) {
        console.error("Error fetching unlocked tokens:", error);
        return 0;
      }
    }
  }, [vestingContract]);

  const getTotalRaisedBnb = useCallback(async () => {
    if (presaleContract) {
      try {
        const soldTokens = await presaleContract.totalRaisedBnb();
        return ethers.formatEther(soldTokens);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getBnbCap = useCallback(async () => {
    if (presaleContract) {
      try {
        const totalCap = await presaleContract.totalCapBnb();
        return ethers.formatEther(totalCap);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getMaxPurchaseAmount = useCallback(async () => {
    if (presaleContract) {
      try {
        const maxPurchase = await presaleContract.maxPurchase();
        return ethers.formatEther(maxPurchase);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getMinPurchaseAmount = useCallback(async () => {
    if (presaleContract) {
      try {
        const minPurchase = await presaleContract.minPurchase();
        return ethers.formatEther(minPurchase);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const buyTokens = useCallback(
    async (ethValue) => {
      if (signer && presaleContract) {
        try {
          const transaction = await presaleContract.buyTokens({
            value: ethers.parseEther(ethValue),
          });
          await transaction.wait();
          return { status: true };
        } catch (error) {
          return { status: false, message: error.message };
        }
      }
    },
    [signer, presaleContract]
  );

  const claimTokens = useCallback(async () => {
    if (signer && vestingContract) {
      try {
        const transaction = await vestingContract.claimTokens();
        await transaction.wait();
        return { status: true };
      } catch (error) {
        return { status: false, message: error.message };
      }
    }
  }, [signer, vestingContract]);

  return {
    erc20Contract,
    presaleContract,
    vestingContract,
    fetchTokenBalance,
    fetchTokenPrice,
    buyTokens,
    fetchVestingInfo,
    fetchUnlockedTokens,
    hasVestingStarted,
    claimTokens,
    getTotalRaisedBnb,
    getBnbCap,
    getMaxPurchaseAmount,
    getMinPurchaseAmount,
  };
};
