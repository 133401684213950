import { useState, useEffect } from "react";
import { BrowserProvider, ethers } from "ethers";
import { BSC_TESTNET_CONFIG } from "../utils/config";

export const useWallet = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [ethBalance, setEthBalance] = useState(0);

  useEffect(() => {
    const savedWallet = localStorage.getItem("walletAddress");
    if (savedWallet) {
      setWalletAddress(savedWallet);
      connectWallet();
    }
  }, [setWalletAddress]);

  const refreshBalance = async (account) => {
    if (provider && account) {
      const balance = await provider.getBalance(account);
      setEthBalance(Number(ethers.formatEther(balance)).toFixed(4));
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [BSC_TESTNET_CONFIG],
        });
        // const currentChainId = await window.ethereum.request({
        //   method: "eth_chainId",
        // });
        // console.log("currentChainId");
        // console.log(currentChainId);
        // console.log("currentChainId");

        // if (currentChainId !== BSC_TESTNET_CONFIG.chainId) {
        //   await window.ethereum.request({
        //     method: "wallet_switchEthereumChain",
        //     params: [{ chainId: BSC_TESTNET_CONFIG.chainId }],
        //   });
        // }

        const provider = new BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const newSigner = await provider.getSigner();
        setSigner(newSigner);
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        setWalletAddress(accounts[0]);
        localStorage.setItem("walletAddress", accounts[0]);
        setProvider(provider);
        let walletBalance = ethers.formatEther(
          await provider.getBalance(accounts[0])
        );
        setEthBalance(Number(walletBalance).toFixed(4));
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else {
      alert("MetaMask not found. Please install MetaMask.");
    }
  };

  const disconnectWallet = () => {
    setWalletAddress(null);
    setProvider(null);
    setSigner(null);
    setEthBalance(0);
    localStorage.removeItem("walletAddress");
  };

  useEffect(() => {
    const savedWalletAddress = localStorage.getItem("walletAddress");

    if (window.ethereum && savedWalletAddress) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          if (provider) {
            const newSigner = await provider.getSigner();
            setSigner(newSigner);
            let walletBalance = ethers.formatEther(
              await provider.getBalance(accounts[0])
            );
            setEthBalance(Number(walletBalance).toFixed(4));
          }
          setWalletAddress(accounts[0]);
          localStorage.setItem("walletAddress", accounts[0]);
        } else {
          setWalletAddress(null);
        }
      });
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    }
  }, [provider]);

  return {
    walletAddress,
    ethBalance,
    setEthBalance,
    provider,
    connectWallet,
    signer,
    disconnectWallet,
    refreshBalance,
  };
};
